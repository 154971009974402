import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Card from '../card';
import Title from 'components/common/title';
import styled, { css } from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import moment from 'moment';
import DateFormats from 'constants/DateFormats';
import { formatAlertName } from 'utils/helpers';
import I18n from 'i18n';
// import MuiButton from '@material-ui/core/Button';
// import RulesIcon from './rules-icon.svg';
// import DownloadIcon from './download-icon.svg';
// import Dialog from 'components/common/dialog';
// import { trackEvent } from 'utils/analytics';
const { lookup } = new I18n();

/**
const Button = styled(MuiButton)(
  p => css`
    ${p.theme.typography.h4};
    text-transform: none;
  `
);

const Image = styled.img`
  width: 34px;
  cursor: pointer;
`;
**/

// custom cells
const HeaderCell = styled(MuiTableCell)(
  p => css`
    ${p.theme.typography.h4};
    border-right: ${p.theme.effects.tableBorder};
    border-bottom: ${p.theme.effects.tableBorder};
    white-space: nowrap;
    &:last-child {
      border-right: none;
    }
  `
);

const NameCell = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RowCell = styled(MuiTableCell)(
  p => css`
    ${p.theme.typography.h5};
  `
);

const DescriptionCell = styled(MuiTableCell)(
  p => css`
    ${p.theme.typography.h6};
  `
);

const Highlights = props => {
  const { data, theme } = props;
  // const [modal, setModal] = useState({});

  /* MVP Removed Rules and Codes column from Alert Highlights
  const downloadCSV = () => {
    alert(':)');
    // TODO: replace with actual filename when we've download feature implemented
    trackEvent('trigger_download', 'filename');
  };

  const openRulesModal = alert => {
    setModal({
      title: alert.label,
      body: 'Temp Content',
      isOpen: true
    });
  };

  const closeRulesModal = () => {
    setModal({});
  };
  */

  return (
    <>
      <Title id="alert-highlights" font="h4">
        {lookup('section_alert_highlights')}
        {/**
        {data.isCombined && (
          <Button
            endIcon={<Image src={DownloadIcon} />}
            onClick={downloadCSV}
          >
            {lookup('highlights_weekly_dl_link')}
          </Button>
        )}
      **/}
      </Title>
      <TableContainer component={Card}>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCell>{lookup('highlights_table_header_name')}</HeaderCell>
              <HeaderCell>{lookup('highlights_table_header_count')}</HeaderCell>
              <HeaderCell>
                {lookup('highlights_table_header_details')}
              </HeaderCell>
              {/** MVP Removed Rules and Codes column from Alert Highlights
                <HeaderCell align="center">
                  {lookup('highlights_table_header_rules')}
                </HeaderCell>
              **/}
              <HeaderCell>{lookup('highlights_table_header_time')}</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.alertBreakdown.map((alert, id) => (
              <TableRow
                key={id}
                style={
                  id % 2
                    ? { backgroundColor: theme.colors.white }
                    : { backgroundColor: theme.colors.stripe }
                }
              >
                <RowCell>
                  <NameCell>
                    {formatAlertName(alert)}
                    {/**
                    {!data.isCombined && (
                      <Image
                        alt="download alert"
                        onClick={downloadCSV}
                        src={DownloadIcon}
                      />
                    )}
                  **/}
                  </NameCell>
                </RowCell>
                <RowCell>
                  {alert.totalAlerts && alert.totalAlerts.toLocaleString()}
                </RowCell>
                <DescriptionCell>{alert.description}</DescriptionCell>
                {/** MVP Removed Rules and Codes column from Alert Highlights
                  <RowCell align="center">
                    <Image
                      alt="rules"
                      onClick={() => openRulesModal(alert)}
                      src={RulesIcon}
                    />
                  </RowCell>
                **/}
                <RowCell>
                  {alert.timestamp &&
                    moment(alert.timestamp).format(DateFormats.timestamp)}
                </RowCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/** MVP Removed Rules and Codes column from Alert Highlights
        <Dialog
          {...modal}
          handleClose={closeRulesModal}
          footer={<Button onClick={closeRulesModal}>Close</Button>}
        />
      **/}
    </>
  );
};

export default withTheme(Highlights);
