import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Card from '../card';
import { motion } from 'framer-motion';
import { getNum } from 'utils/helpers';

const Container = styled(Card)(
  p => `
  height: 99px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  border-radius: 0;

  ${
    p.border &&
    `
    ${p.theme.breakpoints.up('sm')} {
      border-right: ${p.theme.effects.tableBorder};
    }
    ${p.theme.breakpoints.down('xs')} {
      border-bottom: ${p.theme.effects.tableBorder};
    }
  `
  }
`
);

const CardImg = styled.img(
  p => `
  padding-right: ${p.theme.space.md};
`
);

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const SummaryCard = props => {
  const { img, label, value, border } = props;

  return (
    <Container border={border}>
      <CardImg alt={`${label}-count`} src={img} />
      <CardDetails>
        <motion.div
          key={value}
          transition={{
            duration: 1.5
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Typography variant="h1">
            {getNum(value)?.toLocaleString() || ''}
          </Typography>
        </motion.div>
        <Typography variant="h4">{label}</Typography>
      </CardDetails>
    </Container>
  );
};

export default SummaryCard;
