import ENDPOINT from 'utils/api';
import { Project } from 'models';
import { handleErrors } from 'utils/error';

/**
 * Get projects for logged in user
 * @async
 * @param {string} companyId company id
 * @param {string} token auth0 token
 * @return {Object} projects.
 */
async function initProjects(companyId, token) {
  const res = await fetchProjects(companyId, token);
  const projects = Project.init(res);
  return {
    projects,
    selectedProject: Project.getDefaultProject(projects)
  };
}

/**
 * Fetch /projects
 * @async
 * @param {string} companyId company id
 * @param {string} token auth0 token
 * @return {Promise} projects.
 */
async function fetchProjects(companyId, token) {
  try {
    const res = await fetch(`${ENDPOINT}projects?company_id=${companyId}`, {
      headers: {
        'Access-Token': token
      }
    });
    if (!res.ok) {
      throw res;
    }
    return res.json();
  } catch (e) {
    throw await handleErrors(e);
  }
}

export { initProjects };
