export const ENLocalizedStrings = {
  //header
  header_outputs: 'Output',
  header_volumes: 'Volume',
  header_impact: 'Alert Pathway',
  header_logout: 'Sign Out',
  header_projects: 'Projects',

  //summary cards
  card_alerts: 'Records',
  card_hcps: 'Unique HCPs',
  card_patients: 'Unique Patients',
  filter_delivery_date_label: 'Week Of',

  //metric chart
  filter_metric_type_number_of_alert: 'Records',
  filter_metric_type_number_of_hcp: 'Unique HCPs',
  filter_metric_type_number_of_patient: 'Unique Patients',
  metric_x_axis_title: 'Delivery Date',
  chart_alert_legend: 'All Records',
  chart_new_alert_legend: 'Newly Alerted',
  chart_hcp_legend: 'Unique HCPs',
  chart_patient_legend: 'Unique Patients',
  filter_show_all_label: 'Show All',
  filter_show_all_label_info: '(includes any deactivated alerts)',
  filter_weekly_label: 'Weekly',
  filter_total_label: 'Cumulative',
  filter_alert_name_placeholder: 'Alert Name',
  filter_alert_type_placeholder: '# of',

  //highlights
  highlights_weekly_dl_link: "Download This Week's Alert CSV",
  highlights_table_header_name: 'Alert Name',
  highlights_table_header_count: '# of Records',
  highlights_table_header_details: 'Alert Descriptions',
  highlights_table_header_rules: 'Rules/Codes',
  highlights_table_header_time: 'Delivery Timestamp',

  //sections
  section_alert_volume: 'Alert Volume',
  section_alert_summary: 'Weekly Volume',
  section_alert_summary_all_alerts: '(all alerts)',
  section_alert_metrics: 'Volume over Time',
  section_alert_highlights: 'Recent Delivery',
  section_conversion_funnel: 'Alert Pathway',
  section_alert_output: 'Alert Output',

  //roi
  roi_filter_apply_label: 'Apply',
  roi_filter_available_alerts: 'Alerts',
  roi_filter_selected_alerts: 'Steps',
  filter_inactive_prefix: '(Inactive)',
  roi_chart_data_label: 'Patients',
  roi_drag_placeholder: '\\2192  Drag and drop',
  roi_filter_info:
    'Please select a sequence of two or more alerts to visualize an alert pathway.',

  //alert output
  alert_output_filename_filter_suffix: '_filtered',

  //empty state
  alert_volume_empty_state: 'There is no alert volume to display.',
  alert_output_empty_state: 'There is no alert output to display.',
  alert_pathway_empty_state:
    'You must have 2 or more alerts to use this feature.',

  //tooltips
  tooltip_range_picker: (
    <>
      Select a delivery date range.
      <br />
      <br />
      <b>Note:</b> This date range does not pertain to claim dates or dates of
      service.
    </>
  ),
  tooltip_roi_range_picker: (
    <>
      Select a delivery date range to establish the cohort of patients that move
      from your first alert to subsequent alerts in your pathway.
      <br />
      <br />
      <b>Note:</b> This date range does not pertain to claim dates or dates of
      service.
    </>
  ),
  tooltip_alert_output:
    'View any alert output you’ve received in the past 90 days.\n\nDisplayed output reflects what was delivered and the state of the data at the time of delivery.',
  tooltip_alert_summary:
    'Total records, unique HCPs, and unique patients for a given week across all alerts.',
  tooltip_alert_volume:
    'View volume trends over time for individual alerts or across all alerts. Select the cumulative tab to see overall delivery growth.\n\nVolumes reflect the output that was delivered. Cross-alert volume includes output from any alerts that have since been deactivated.',
  tooltip_alert_pathway:
    'Analyze how patients move through a sequence of alerts. Choose the first alert in the sequence along with a date range to establish a cohort of patients. Then add subsequent alerts to see how many of those patients went on to appear on the alerts in the sequence.',

  //footer
  copyright: '©',
  copyright_start_year_text: '2015 -',
  footer_text: 'KomodoHealth Inc.',
  privacy_notice_text: 'Privacy Notice',
  terms_of_use_text: 'Terms of Use',
  privacy_notice_link: 'https://www.komodohealth.com/privacy-notice',
  terms_of_use_link: 'https://www.komodohealth.com/terms-of-service',

  //access denied
  access_denied_dialog_title: 'Access Denied',
  access_denied_dialog_body:
    "doesn't have access to Pulse. Please reach out to your Komodo Health Customer Success representative.",

  //error handling
  unhandled_exception_label:
    'Something went wrong. Please reach out to your Komodo Health Customer Success representative.'
};
