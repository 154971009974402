import MuiTooltip from '@material-ui/core/Tooltip';
import MuiIconButton from '@material-ui/core/IconButton';
import styled, { css } from 'styled-components';
import InfoIcon from './info.svg';

const IconButton = styled(MuiIconButton)`
  ${({ theme }) => `
    padding: ${theme.space.sm};
    margin-right: -${theme.space.md};
  `}
`;

const Tooltip = styled(props => (
  <MuiTooltip
    classes={{ popper: props.className, tooltip: 'tooltip' }}
    {...props}
  />
))(
  p => css`
    & .tooltip {
      background-color: ${p.theme.colors.white};
      box-shadow: ${p.theme.effects.boxShadow};
      ${p.theme.typography.tooltip};
      color: ${p.theme.colors.textGrey};
      padding: ${p.theme.space.sm};
      border: ${p.theme.effects.tableBorder};
      white-space: pre-line;
      ${p.placement === 'bottom-end' && `margin-right: ${p.theme.space.md};`}
  `
);

const Info = ({ copy, placement }) => {
  return (
    <Tooltip title={copy} placement={placement || 'right-start'}>
      <IconButton>
        <img alt="information" src={InfoIcon} />
      </IconButton>
    </Tooltip>
  );
};

export default Info;
