import { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Title from 'components/common/title/';
import Summary from './components/summary';
import Metric from './components/metric';
import Highlights from './components/highlights';
import Loader from 'components/common/loader';
import Divider from 'components/common/divider';
import Info from 'components/common/info';
import AppContext from 'AppContext';
import { trackView } from 'utils/analytics';
import ErrorBoundary from 'components/common/error';
import { initDashboard } from 'services';
import I18n from 'i18n';
const { lookup } = new I18n();

const Dashboard = () => {
  const appContext = useContext(AppContext);
  const [state, setState] = useState({
    selectedProject: null,
    loading: true
  });

  async function init(context) {
    try {
      const selectedProject = await initDashboard(context);
      setState({
        selectedProject,
        loading: false
      });
    } catch (e) {
      context.handleErrorMessage(e, context.logout, setState);
    }
  }

  useEffect(() => {
    setState({ loading: true });
    init(appContext);
  }, [appContext]);

  useEffect(() => {
    trackView('volume');
  }, []);

  return (
    <Container>
      <Loader loading={state.loading} />
      <Title id="alert-volume">{lookup('section_alert_volume')}</Title>
      {!state.loading && (
        <>
          {state?.selectedProject?.selectedEntry ? (
            <>
              <ErrorBoundary>
                <Summary selectedProject={state.selectedProject} />
              </ErrorBoundary>
              <Divider sm />
              <Title id="alert-metrics" font="h4">
                {lookup('section_alert_metrics')}
                <Info copy={lookup('tooltip_alert_volume')} />
              </Title>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ErrorBoundary>
                    <Metric {...state} />
                  </ErrorBoundary>
                </Grid>
              </Grid>
              <Divider sm />
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <ErrorBoundary>
                    <Highlights data={state.selectedProject.latestEntry} />
                  </ErrorBoundary>
                </Grid>
              </Grid>
            </>
          ) : (
            <>{lookup('alert_volume_empty_state')}</>
          )}
        </>
      )}
    </Container>
  );
};

export default Dashboard;
