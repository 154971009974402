/**
 * Util function to check if Integer and return
 * @param {val} val
 * @returns {(integer|null)}
 */
export const getNum = val => {
  return Number.isInteger(val) ? val : null;
};

/**
 * Helper function to format alert name
 * @param {Object} alert
 * @return {string} formatted alert name
 */
export const formatAlertName = alert => {
  return `${alert?.id ? `${alert.id} - ` : ''}${alert?.name ?? ''}`;
};

/**
 * Helper function to get text width using canvas
 * @param {string} text
 * @param {Object} font
 * @param {integer} offset
 * @return {integer} calculated width
 */
export const getTextWidth = (text, font, offset = 0) => {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = `${font.fontWeight} ${font.fontSize} ${font.fontFamily}`;
  const metrics = context.measureText(text);
  return metrics.width + offset;
};
