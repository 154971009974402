import MuiSelect from '@material-ui/core/Select';
import styled, { css } from 'styled-components';

const PaperProps = {
  style: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    maxHeight: '300px'
  }
};

const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null,
  PaperProps: PaperProps
};

const StyledMuiSelect = styled(MuiSelect)(
  p => css`
    width: ${p.width || '270px'};
    ${p.theme.typography.h4};
    .MuiSelect-select:focus {
      background-color: inherit;
    }
    .MuiSelect-select {
      padding-left: ${p.theme.space.sm};
    }
    ${p.theme.breakpoints.down('sm')} {
      width: auto;
    }
  `
);

const Select = props => {
  return <StyledMuiSelect {...props} MenuProps={MenuProps} />;
};

export default Select;
