import { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from '../dashboard';
import Layout from 'components/common/layout';
import Impact from '../impact';
import Output from '../output';
import Loader from 'components/common/loader';
import AppContext from 'AppContext';
import AppRoutes from 'constants/AppRoutes';
import { useAuth0 } from 'auth0/react-auth0-spa';
import { setIdentity, trackEvent } from 'utils/analytics';
import ErrorBoundary from 'components/common/error';
import Dialog from 'components/common/dialog';
import Button from '@material-ui/core/Button';
import { initUser, initProjects } from 'services';
import { handleErrorMessage } from 'utils/error';
import I18n from 'i18n';
const { lookup } = new I18n();

/**
 * Root Component for protected routes
 * @param {Object} props
 */
const Root = props => {
  // read user from auth0
  const { user, getTokenSilently, logout } = useAuth0();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [state, setState] = useState({
    projects: null,
    selectedProject: null,
    companyId: null,
    companyLogo: null,
    companyAcronym: null,
    token: null,
    isAuthorized: false,
    loading: true
  });

  /**
   * Authenticate user and fetch projects
   * @param {Object} user auth0 user object
   * @param {function} getToken function to silently retrieve auth0 token
   * @param {function} logout function to logout user
   */
  async function init(user, getToken, logout) {
    try {
      const token = await getToken();
      const authorization = await initUser(user, token);

      try {
        // get first company from companies array and read id and image_url
        const {
          companies: [{ id, image_url, acronym } = {}]
        } = await authorization.json();
        if (id) {
          const projects = await initProjects(id, token);
          setState({
            ...projects,
            token,
            companyId: id,
            companyLogo: image_url,
            companyAcronym: acronym,
            isAuthorized: true,
            loading: false
          });
        } else {
          setDialogOpen(true);
        }
      } catch (e) {
        handleErrorMessage(e, logout, setState);
      }
    } catch (e) {
      if (e?.AUTH_ERROR) {
        //no access
        setDialogOpen(true);
      } else {
        handleErrorMessage(e, logout, setState);
      }
    }
  }

  /**
   * Change global Project
   * @param {string} project
   */
  const changeProject = project => {
    setState({
      ...state,
      selectedProject: project
    });

    trackEvent('ta_changed', project.name);
  };

  /**
   * Logout user
   */
  const handleDialogClose = () => {
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    setIdentity(user);
    init(user, getTokenSilently, logout);
  }, [user, getTokenSilently, logout]);

  return (
    <>
      <Loader loading={state.loading} />
      {user && state.isAuthorized && (
        <AppContext.Provider
          value={{
            ...state,
            user,
            changeProject,
            logout,
            handleErrorMessage
          }}
        >
          <Layout>
            {state.projects && state.selectedProject && (
              <Switch>
                <Route
                  path={AppRoutes.OUTPUT}
                  render={props => (
                    <ErrorBoundary>
                      <Output />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path={AppRoutes.VOLUME}
                  render={props => (
                    <ErrorBoundary>
                      <Dashboard />
                    </ErrorBoundary>
                  )}
                />
                <Route
                  path={AppRoutes.IMPACT}
                  render={props => (
                    <ErrorBoundary>
                      <Impact />
                    </ErrorBoundary>
                  )}
                />
                <Redirect to={AppRoutes.OUTPUT} />
              </Switch>
            )}
          </Layout>
        </AppContext.Provider>
      )}
      <Dialog
        title={lookup('access_denied_dialog_title')}
        body={
          <span>
            <strong>{user.email} </strong>
            {lookup('access_denied_dialog_body')}
          </span>
        }
        handleClose={handleDialogClose}
        isOpen={dialogOpen}
        footer={<Button onClick={handleDialogClose}>OK</Button>}
      />
    </>
  );
};

export default Root;
