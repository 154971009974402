import get from 'lodash/get';

class User {
  /**
   * 'Constructor' for User Model
   * @param {Object} obj
   */
  constructor(obj) {
    if (obj) {
      this.id = obj.sub;
      this.displayName = get(obj, 'nickname', '');
      this.logoPath = obj.logoPath;
      this.firstName = get(obj, 'firstName', '');
      this.lastName = get(obj, 'lastName', '');
      this.email = get(obj, 'email', '');
    }
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export { User };
