import 'react-app-polyfill/ie11';
import 'core-js';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from 'auth0/react-auth0-spa';
import config from 'auth0/auth_config.json';
import 'utils/bugsnag';
import ErrorBoundary from 'components/common/error';
import { ThemeProvider as MuiThemeProviderV4 } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProviderV5 } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import CssBaseline from '@material-ui/core/CssBaseline';
import { themeV4, themeV5 } from 'constants/theme';
import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUIX_ID);

ReactDOM.render(
  <MuiThemeProviderV4 theme={themeV4}>
    <MuiThemeProviderV5 theme={themeV5}>
      <ThemeProvider theme={themeV4}>
        <CssBaseline />
        <ErrorBoundary>
          <Auth0Provider
            domain={config.domain}
            client_id={config.clientId}
            redirect_uri={window.location.origin}
            audience={config.audience}
          >
            <App />
          </Auth0Provider>
        </ErrorBoundary>
      </ThemeProvider>
    </MuiThemeProviderV5>
  </MuiThemeProviderV4>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
