import { memo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { withTheme } from '@material-ui/core/styles';
import MuiContainer from '@material-ui/core/Container';
import styled from 'styled-components';

// enables extra features including arearange chart
HighchartsMore(Highcharts);

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

const Container = styled(MuiContainer)`
  ${({ fullheight }) =>
    fullheight
      ? `
    height: 100%;
    #chart-container{
      height: 100%;
    }
  `
      : `
    min-height: 400px;
  `}
`;

const ChartContainer = props => {
  const { chart, fullheight } = props;

  return (
    <>
      {chart && (
        <Container disableGutters fullheight={fullheight}>
          <HighchartsReact
            highcharts={Highcharts}
            options={chart}
            key={Math.random()}
            containerProps={{ id: 'chart-container' }}
          />
        </Container>
      )}
    </>
  );
};

export default withTheme(memo(ChartContainer));
