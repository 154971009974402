import { useState, useEffect, useContext } from 'react';
import Container from '@material-ui/core/Container';
import { ConversionChart } from './components/chart/ConversionChart';
import ChartContainer from 'components/dashboard/components/chart';
import Card from 'components/dashboard/components/card';
import Title from 'components/common/title';
import { withTheme } from '@material-ui/core/styles';
import AppContext from 'AppContext';
import Loader from 'components/common/loader';
import Info from 'components/common/info';
import { initConversion, initDashboard } from 'services';
import { trackView } from 'utils/analytics';
import Grid from '@material-ui/core/Grid';
import DragList from './components/draglist';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import styled from 'styled-components';
import I18n from 'i18n';
const { lookup } = new I18n();

const GridWithBorder = styled(Grid)(
  p => `
    ${p.theme.breakpoints.up('md')} {
      border-right: ${p.theme.effects.tableBorder};
    }
    ${p.theme.breakpoints.down('sm')} {
      border-bottom: ${p.theme.effects.tableBorder};
    }
  `
);

const Wrapper = styled(Container)`
  ${({ theme }) =>
    `
    position: relative;
    .MuiBackdrop-root{
      z-index: ${theme.zIndex.drawer + 1};
      background-color: ${theme.colors.loaderBackdrop};
      position: absolute;
    }
  `}
`;

// spacing api generates negative margin, this should take care of it
const ContainerGrid = styled(Grid)`
  margin-top: 0;
`;

const initialState = {
  data: null,
  selectedProject: null,
  loading: true
};

const Impact = props => {
  const appContext = useContext(AppContext);
  const [state, setState] = useState(initialState);
  const [chartLoading, setChartLoading] = useState(false);

  async function getConversion(params) {
    setChartLoading(true);
    try {
      const conversion = await initConversion(appContext, params);
      setState({
        ...state,
        data: ConversionChart({ ...props, ...conversion })
      });
      setChartLoading(false);
    } catch (e) {
      appContext.handleErrorMessage(e, appContext.logout, setState);
    }
  }

  useEffect(() => {
    // using Dashboard service to get alertNames here
    // temporary
    async function init() {
      try {
        const selectedProject = await initDashboard(appContext);
        setState({
          selectedProject,
          data: null,
          loading: false
        });
      } catch (e) {
        appContext.handleErrorMessage(e, appContext.logout, setState);
      }
    }

    setState(initialState);
    init();
  }, [appContext, props]);

  useEffect(() => {
    trackView('alert_pathway');
  }, []);

  return (
    <Container>
      <Loader loading={state.loading} />
      <Title>
        {lookup('section_conversion_funnel')}
        <Info copy={lookup('tooltip_alert_pathway')} />
      </Title>
      {!state.loading && (
        <>
          {state.selectedProject?.alertNames?.length > 1 &&
          state.selectedProject?.entries?.length > 1 ? (
            <>
              <Card>
                <Wrapper>
                  <Backdrop open={chartLoading}>
                    <CircularProgress color="primary" />
                  </Backdrop>
                  <ContainerGrid container spacing={4}>
                    <GridWithBorder item xs={12} sm={12} md={5} lg={5}>
                      <DragList
                        applyFilters={getConversion}
                        selectedProject={state.selectedProject}
                      />
                    </GridWithBorder>
                    <Grid item xs={12} sm={12} md={7} lg={7}>
                      <ChartContainer fullheight={1} chart={state.data} />
                    </Grid>
                  </ContainerGrid>
                </Wrapper>
              </Card>
            </>
          ) : (
            <>{lookup('alert_pathway_empty_state')}</>
          )}
        </>
      )}
    </Container>
  );
};

export default withTheme(Impact);
