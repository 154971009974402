class AlertConversions {
  /**
   * 'Constructor' for AlertConversions Model
   * @param {Object} obj
   */
  constructor(obj) {
    if (obj) {
      Object.assign(this, initConversions(obj));
    }
  }
}

/**
 * Init alert categories, barSeries and areaSeries for conversion chart
 * @param {Array} entries
 * @returns {Object}
 */
const initConversions = entries => {
  let retVal = {
    categories: [],
    barSeries: {
      data: []
    },
    areaSeries: {
      data: []
    }
  };

  if (Array.isArray(entries)) {
    entries.forEach(entry => {
      retVal.categories.push(entry.label);
      retVal.barSeries.data.push(entry.detected);
      retVal.areaSeries.data.push([0, entry.detected]);
    });
  }

  return retVal;
};

export { AlertConversions };
