import { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import AppContext from 'AppContext';
import DATE_FORMATS from 'constants/DateFormats';
import { DOWNLOAD_THRESHOLD_IN_DAYS } from 'constants/AppConstants';
import { AlertOutputService } from 'services/AlertOutputService';
import { Box, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import {
  DataGridPro as MuiDataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport
} from '@mui/x-data-grid-pro';
import { withTheme } from '@material-ui/core/styles';
import Card from 'components/dashboard/components/card';
import Select from 'styled/Select';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { trackEvent } from 'utils/analytics';
import { formatAlertName } from 'utils/helpers';
import I18n from 'i18n';
const { lookup } = new I18n();

const DataGridPro = styled(MuiDataGridPro)(
  p => css`
    .MuiDataGrid-columnHeaderTitle {
      ${p.theme.typography.h6};
    }
  `
);

const DataGridWrapper = styled(Box)`
  min-height: 400px;
  height: 70vh;
`;

const initialData = {
  rows: [],
  columns: []
};

const DataGrid = props => {
  let {
    selectedProject: { alertNames, entries, companyAcronym, therapyArea }
  } = props;

  const filteredEntries = useMemo(
    () =>
      entries.filter(entry =>
        moment(entry.date, DATE_FORMATS.iso).isSameOrAfter(
          moment().subtract(DOWNLOAD_THRESHOLD_IN_DAYS, 'days')
        )
      ),
    [entries]
  );

  const appContext = useContext(AppContext);
  const alertOutputService = useMemo(
    () => new AlertOutputService(appContext.token),
    [appContext.token]
  );

  const [alert, setAlert] = useState({});
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(false);
  const [columnFilter, setColumnFilter] = useState(false);

  const handleInputChange = (name, e) => {
    setAlert({
      ...alert,
      [name]: e.target.value
    });
    setFilter(false);
    setColumnFilter(false);
    trackEvent(`output_${name}_changed`, e.target.value);
  };

  const init = useCallback(
    async alert => {
      try {
        const data = await alertOutputService.get(
          alert.internalAlertId,
          alert.date,
          props.theme.typography.h6
        );
        setData(data);
      } catch (e) {
        appContext.handleErrorMessage(e, appContext.logout, setAlert);
      } finally {
        setLoading(false);
      }
    },
    [alertOutputService, appContext, props.theme.typography.h6]
  );

  const generateAlertName = () => {
    return `${companyAcronym}_${therapyArea}_${
      alertNames.find(
        ({ internalAlertId }) => internalAlertId === alert.internalAlertId
      ).name
    }_${moment(alert.date, DATE_FORMATS.iso).format(DATE_FORMATS.delivery)}${
      filter || columnFilter
        ? lookup('alert_output_filename_filter_suffix')
        : ''
    }`.replace(/\s+/g, '_');
  };

  const DataGridToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          printOptions={{
            disableToolbarButton: true
          }}
          csvOptions={{
            fileName: generateAlertName()
          }}
        />
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    setAlert({
      internalAlertId: alertNames?.[0]?.internalAlertId,
      date: filteredEntries?.[0]?.date
    });
  }, [alertNames, filteredEntries, appContext]);

  useEffect(() => {
    if (alert.internalAlertId && alert.date) {
      setLoading(true);
      setData(initialData);
      init(alert);
    }
    // TODO: cleanup function here
  }, [alert, init]);

  return (
    <>
      {alert.internalAlertId && alert.date && (
        <Card>
          <Box p={2}>
            <Box component="span" mr={2}>
              <FormControl>
                <InputLabel>
                  {lookup('filter_alert_name_placeholder')}
                </InputLabel>
                <Select
                  id="alert-name-select"
                  value={alert.internalAlertId}
                  onChange={e => handleInputChange('internalAlertId', e)}
                >
                  {alertNames.map((alert, id) => {
                    return (
                      <MenuItem key={id} value={alert.internalAlertId}>
                        {formatAlertName(alert)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <FormControl>
              <InputLabel>{lookup('filter_delivery_date_label')}</InputLabel>
              <Select
                id="delivery-date-select"
                aria-label="alerts delivered on"
                value={alert.date}
                onChange={e => handleInputChange('date', e)}
              >
                {filteredEntries.map((entry, id) => {
                  return (
                    <MenuItem key={id} value={entry.date}>
                      {moment(entry.date).format(DATE_FORMATS.standard)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <DataGridWrapper pt={2}>
              <DataGridPro
                loading={loading}
                key={`output-grid-${alert.internalAlertId}-${alert.date}`}
                columns={data.columns}
                rows={data.rows}
                disableSelectionOnClick
                disableColumnPinning
                onFilterModelChange={model => {
                  const isFilterActive = model.items.some(item => item.value);
                  setFilter(isFilterActive);
                  if (isFilterActive) {
                    trackEvent('output_text_filter');
                  }
                }}
                onColumnVisibilityModelChange={model => {
                  const isFilterActive = Object.keys(model).some(
                    key => !model[key]
                  );
                  setColumnFilter(isFilterActive);
                  if (isFilterActive) {
                    trackEvent('output_column_filter');
                  }
                }}
                components={{
                  ...(!loading && {
                    Toolbar: DataGridToolbar
                  })
                }}
              />
            </DataGridWrapper>
          </Box>
        </Card>
      )}
      {!(alert.internalAlertId && alert.date) &&
        lookup('alert_output_empty_state')}
    </>
  );
};

export default withTheme(DataGrid);
