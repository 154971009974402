import Select from 'styled/Select';
import {
  InputLabel,
  MenuItem,
  FormControl as MuiFormControl,
  Typography
} from '@material-ui/core';
import { MetricChartFilterConfig } from 'constants/AppConstants';
import styled from 'styled-components';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from 'styled/ToggleButton';
import Slider from 'components/common/slider';
import Info from 'components/common/info';
import moment from 'moment';
import DATE_FORMATS from 'constants/DateFormats';
import { formatAlertName } from 'utils/helpers';
import I18n from 'i18n';

const { lookup } = new I18n();

const FilterContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.space.md} 0;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      padding: ${theme.space.sm} 0;
    }
  `}
`;

const SelectContainer = styled.div`
  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      display: flex;
      flex-direction: column;
    }
  `}
`;

const FormControl = styled(MuiFormControl)`
  ${({ theme }) => `
    &:first-child {
      margin-right: ${theme.space.md};
    }
    ${theme.breakpoints.down('sm')} {
      &:first-child {
        margin-right: 0;
      }
    }
  `}
`;

const SliderContainer = styled.div`
  ${({ theme }) => `
    max-width: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: ${theme.space.md} ${theme.space.md} 0 0;
    button {
      margin-left: ${theme.space.md};
    }
  `}
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChartFilter = props => {
  const {
    metric,
    handleMetricChange,
    internalAlertId,
    alertNames,
    handleAlertNameChange,
    isWeekly,
    handleWeeklyToggle,
    startDate,
    endDate,
    dates,
    updateDateRange
  } = props;

  const renderSlider = dates.length - 1 > 0;

  return (
    <>
      <FilterContainer>
        <SelectContainer>
          <FormControl>
            <InputLabel>{lookup('filter_alert_name_placeholder')}</InputLabel>
            <Select
              id="alert-name-select"
              value={internalAlertId}
              onChange={handleAlertNameChange}
            >
              {alertNames.map((alert, id) => {
                return (
                  <MenuItem key={id} value={alert.internalAlertId}>
                    {alert !== MetricChartFilterConfig.showAll &&
                      formatAlertName(alert)}
                    {alert === MetricChartFilterConfig.showAll && (
                      <>
                        {alert.name}
                        <Typography variant="body2" component="span">
                          &nbsp;{lookup('filter_show_all_label_info')}
                        </Typography>
                      </>
                    )}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>{lookup('filter_alert_type_placeholder')}</InputLabel>
            <Select
              id="metric-type-select"
              value={metric}
              onChange={handleMetricChange}
            >
              {MetricChartFilterConfig.metricTypes.map((metric, id) => {
                return (
                  <MenuItem key={id} value={metric.type}>
                    {metric.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </SelectContainer>
        {renderSlider && (
          <SliderContainer>
            <Slider
              key={Math.random()}
              tooltips={true}
              format={{
                from: Number,
                to: value => {
                  return moment(dates[parseInt(value)]).format(
                    DATE_FORMATS.standard
                  );
                }
              }}
              onSet={val => {
                if (
                  val[0] !==
                    moment(dates[startDate]).format(DATE_FORMATS.standard) ||
                  val[1] !==
                    moment(dates[endDate]).format(DATE_FORMATS.standard)
                ) {
                  updateDateRange({ startDate: val[0], endDate: val[1] });
                }
              }}
              behaviour="tap-drag"
              range={{ min: 0, max: dates.length - 1 }}
              start={[startDate, endDate]}
              connect
            />
            <Info
              placement="bottom-end"
              copy={lookup('tooltip_range_picker')}
            />
          </SliderContainer>
        )}
      </FilterContainer>
      <ToggleContainer>
        <ToggleButtonGroup
          value={isWeekly}
          exclusive
          onChange={(e, val) => {
            val !== null && handleWeeklyToggle(val);
          }}
          size="small"
        >
          <ToggleButton value={true}>
            {lookup('filter_weekly_label')}
          </ToggleButton>
          <ToggleButton value={false}>
            {lookup('filter_total_label')}
          </ToggleButton>
        </ToggleButtonGroup>
      </ToggleContainer>
    </>
  );
};

export default ChartFilter;
