import { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Title from 'components/common/title/';
import Loader from 'components/common/loader';
import Info from 'components/common/info';
import AppContext from 'AppContext';
import { trackView } from 'utils/analytics';
import ErrorBoundary from 'components/common/error';
import { initDashboard } from 'services';
import { DataGrid } from './components';
import I18n from 'i18n';
const { lookup } = new I18n();

const Output = () => {
  const appContext = useContext(AppContext);
  const [state, setState] = useState({
    selectedProject: null,
    loading: true
  });

  async function init(context) {
    try {
      // move this call to /root
      const selectedProject = await initDashboard(context);
      setState({
        selectedProject,
        loading: false
      });
    } catch (e) {
      context.handleErrorMessage(e, context.logout, setState);
    }
  }

  useEffect(() => {
    setState({ loading: true });
    init(appContext);
  }, [appContext]);

  useEffect(() => {
    trackView('output');
  }, []);

  return (
    <Container>
      <Loader loading={state.loading} />
      <Title id="alert-output">
        {lookup('section_alert_output')}
        <Info copy={lookup('tooltip_alert_output')} />
      </Title>
      {state?.selectedProject && (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ErrorBoundary>
              <DataGrid {...state} />
            </ErrorBoundary>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Output;
