import { useEffect, useRef } from 'react';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import styled, { css } from 'styled-components';

const StyledSlider = styled(Nouislider)`
  ${({ theme }) => css`
    width: 100%;
    margin-right: ${theme.space.sm};
    height: 12px;

    .noUi-tooltip {
      ${theme.typography.h6};
      background: inherit;
      border: none;
    }

    .noUi-handle {
      &:focus {
        outline: none;
      }
      cursor: pointer;
    }

    .noUi-connect {
      background: ${theme.colors.komodoPurple};
    }
    //////////
    // temp styles for handle, will replace with an svg
    .noUi-handle {
      top: -9px;
      width: 16px;
      right: -8px;
      &:after {
        display: none;
      }
      &:before {
        display: none;
      }
    }
    //////////
  `}
`;

const Slider = props => {
  const {
    range: { min, max }
  } = props;

  const sliderRef = useRef(null);

  // check if slider has more than 1 date
  const renderSlider = !(min === 0 && max === 0);

  useEffect(() => {
    if (renderSlider) {
      // initialize tooltip handler
      mergeTooltips(sliderRef.current, 30, ' - ');
    }
  }, [renderSlider]);

  // do not render slider
  if (!renderSlider) {
    return null;
  }

  return <StyledSlider instanceRef={sliderRef} {...props} />;
};

/**
 * Merging overlapping tooltips
 * Note: This function uses innerHTML to merge tooltips, it is not a security concern since it doesn't insert any user-provided values.
 * https://github.com/leongersen/noUiSlider/issues/1032
 * https://refreshless.com/nouislider/examples/#section-merging-tooltips
 */
const mergeTooltips = (slider, threshold, separator) => {
  const textIsRtl = getComputedStyle(slider).direction === 'rtl';
  const isRtl = slider.noUiSlider.options.direction === 'rtl';
  const isVertical = slider.noUiSlider.options.orientation === 'vertical';
  let tooltips = slider.noUiSlider.getTooltips();
  let origins = slider.noUiSlider.getOrigins();

  // Move tooltips into the origin element. The default stylesheet handles this.
  tooltips.forEach(function (tooltip, index) {
    if (tooltip) {
      origins[index].appendChild(tooltip);
    }
  });

  slider.noUiSlider.on('update', function (
    values,
    handle,
    unencoded,
    tap,
    positions
  ) {
    let pools = [[]];
    let poolPositions = [[]];
    let poolValues = [[]];
    let atPool = 0;

    // Assign the first tooltip to the first pool, if the tooltip is configured
    if (tooltips[0]) {
      pools[0][0] = 0;
      poolPositions[0][0] = positions[0];
      poolValues[0][0] = values[0];
    }

    for (let i = 1; i < positions.length; i++) {
      if (!tooltips[i] || positions[i] - positions[i - 1] > threshold) {
        atPool++;
        pools[atPool] = [];
        poolValues[atPool] = [];
        poolPositions[atPool] = [];
      }

      if (tooltips[i]) {
        pools[atPool].push(i);
        poolValues[atPool].push(values[i]);
        poolPositions[atPool].push(positions[i]);
      }
    }

    pools.forEach(function (pool, poolIndex) {
      const handlesInPool = pool.length;

      for (let j = 0; j < handlesInPool; j++) {
        const handleNumber = pool[j];

        if (j === handlesInPool - 1) {
          let offset = 0;

          poolPositions[poolIndex].forEach(function (value) {
            offset += 1000 - 10 * value;
          });

          const direction = isVertical ? 'bottom' : 'right';
          const last = isRtl ? 0 : handlesInPool - 1;
          const lastOffset = 1000 - 10 * poolPositions[poolIndex][last];
          offset =
            (textIsRtl && !isVertical ? 100 : 0) +
            offset / handlesInPool -
            lastOffset;

          // Center this tooltip over the affected handles
          const uniqueArray = [...new Set(poolValues[poolIndex])];
          tooltips[handleNumber].innerHTML = uniqueArray.join(separator);
          tooltips[handleNumber].style.display = 'block';
          tooltips[handleNumber].style[direction] = offset + '%';
        } else {
          // Hide this tooltip
          tooltips[handleNumber].style.display = 'none';
        }
      }
    });
  });
};

export default Slider;
