import I18n from 'i18n';
const { lookup } = new I18n();

const ConversionChart = props => {
  const { categories, barSeries, areaSeries, theme } = props;

  return {
    credits: {
      enabled: false
    },
    chart: {
      type: 'bar',
      spacingRight: 150,
      style: {
        fontFamily: theme.typography.fontFamily
      },
      events: {
        render() {
          let chart = this;

          // custom code to append categories to bars.
          if (chart.series.length > 0) {
            if (chart.categoryGroup) {
              chart.categoryGroup.destroy();
            }
            chart.categoryGroup = chart.renderer
              .g('categoryGroup')
              .attr({ zIndex: 999 })
              .add();
            chart.series[0].points.forEach((point, index) => {
              const reversedIndex = chart.series[0].points.length - 1 - index;
              chart.renderer
                .text(
                  `${index + 1}. ${point.category}`,
                  chart.plotLeft,
                  chart.series[0].points[reversedIndex].barX
                )
                .css(theme.typography.h5)
                .add(chart.categoryGroup);

              if (point.dataLabel) {
                // position data labels
                point.dataLabel.attr({
                  x: chart.plotWidth
                });
              }
            });
          }
        }
      }
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    xAxis: {
      visible: false,
      categories
    },
    yAxis: {
      visible: false,
      min: 0,
      minRange: 1
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
          style: theme.typography.h6,
          useHTML: true,
          formatter: function () {
            return `${this.y.toLocaleString()} ${lookup(
              'roi_chart_data_label'
            )} <br/> ${
              this.series.dataMax
                ? Math.ceil((100 * this.y) / this.series.dataMax)
                : '0'
            } %`;
          }
        },
        color: theme.colors.komodoPurple
      },
      series: {
        maxPointWidth: 25,
        minPointLength: 5,
        borderRadius: 3
      }
    },
    tooltip: {
      enabled: false
    },
    series: [
      barSeries,
      {
        ...areaSeries,
        type: 'arearange',
        linkedTo: ':previous',
        enableMouseTracking: false,
        color: theme.colors.light,
        zIndex: -1,
        marker: {
          enabled: false
        },
        animation: {
          duration: 2000
        }
      }
    ]
  };
};

export { ConversionChart };
