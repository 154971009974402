import ToggleButton from '@material-ui/lab/ToggleButton';
import styled, { css } from 'styled-components';

export default styled(ToggleButton)(
  p => css`
    border-width: 2px;
    width: 140px;
    ${p.theme.typography.button};
    color: ${p.theme.colors.textGrey};
    &.Mui-selected {
      color: ${p.theme.colors.textGrey};
      font-weight: bold;
    }
  `
);
