import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Root from './components/root';
import AppRoutes from 'constants/AppRoutes';
import Loader from 'components/common/loader';
import { useAuth0 } from 'auth0/react-auth0-spa';
import PrivateRoute from 'auth0/PrivateRoute';

function App() {
  const { loading } = useAuth0();

  if (loading) {
    return <Loader loading={loading} />;
  }

  return (
    <Router>
      <Switch>
        <PrivateRoute path={AppRoutes.APP} component={Root} />
        <Redirect to={AppRoutes.APP} />
      </Switch>
    </Router>
  );
}

export default App;
