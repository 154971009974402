const ENV = {
  localhost: 'http://localhost:8000',
  dev: 'https://pulse-api-dev.komodohealth.net',
  qa: 'https://pulse-api-qa.komodohealth.net',
  staging: 'https://pulse-api-staging.komodohealth.net',
  pulse: 'https://pulse-api.komodohealth.net'
};

const ENDPOINT = () => {
  if (process.env.REACT_APP_ENV) {
    return `${ENV[process.env.REACT_APP_ENV]}/api/v1/`;
  }
  const hostname = window.location.hostname;
  const env =
    hostname.split('.')[0].split('-')[1] ||
    hostname.split('.')[0].split('-')[0];
  return `${ENV[env]}/api/v1/`;
};

export default ENDPOINT();
