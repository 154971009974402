import styled from 'styled-components';

const Container = styled.div(
  p => `
  padding: ${
    p.lg ? p.theme.space.lg : p.md ? p.theme.space.md : p.theme.space.sm
  } 0;
`
);

const Divider = props => {
  return <Container {...props} />;
};

export default Divider;
