import { useState, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Container from '@material-ui/core/Container';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import HeaderLogo from './header-logo.svg';
import MuiIconButton from 'styled/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AppContext from 'AppContext';
import AppRoutes from 'constants/AppRoutes';
import { useLocation, withRouter } from 'react-router-dom';
import { useAuth0 } from 'auth0/react-auth0-spa';
import { trackEvent } from 'utils/analytics';
import I18n from 'i18n';
const { lookup } = new I18n();

const Wrapper = styled.div(
  p => `
  display: flex;
  align-items: center;
  color: ${p.theme.colors.light};
`
);

const IconButton = styled(MuiIconButton)`
  height: 70px;
`;

const Logo = styled.img(
  p => `
  padding-right: ${p.theme.space.md};
`
);

const CustomerLogo = styled.img(
  p => `
  height: 46px;
  margin-left: ${p.theme.space.sm};
`
);

const Tabs = styled(MuiTabs)`
  flex-grow: 1;
`;

const TabIndicator = {
  color: 'primary',
  style: {
    height: '5px'
  }
};

const Tab = styled(MuiTab)(
  p => css`
    height: 70px;
    ${p.theme.typography.h4Inverse};
    opacity: 1;
    &.Mui-selected {
      font-weight: bold;
    }
  `
);

const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  keepMounted: true,
  getContentAnchorEl: null,
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
};

const initTabProps = index => {
  return {
    id: `header-tab-${index}`,
    'aria-controls': `header-tabpanel-${index}`
  };
};

/**
 * Detects active route from location's pathname
 * @param {Object} location
 * @returns {integer}
 */
const getCurrentRoute = location => {
  if (location.pathname.includes(AppRoutes.OUTPUT)) {
    return 0;
  } else if (location.pathname.includes(AppRoutes.VOLUME)) {
    return 1;
  } else if (location.pathname.includes(AppRoutes.IMPACT)) {
    return 2;
  } else {
    return 0;
  }
};

const Header = props => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(getCurrentRoute(location));
  const [anchorElProjects, setAnchorElProjects] = useState(null);
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const { logout } = useAuth0();

  const handleProjectsMenu = event => {
    setAnchorElProjects(event.currentTarget);
  };

  const handleProfileMenu = event => {
    setAnchorElProfile(event.currentTarget);
  };

  const changeProject = id => {
    appContext.changeProject(id);
    handleProjectsMenuClose();
  };

  const handleProjectsMenuClose = event => {
    setAnchorElProjects(null);
  };

  const handleProfileMenuClose = () => {
    setAnchorElProfile(null);
  };

  const changeRoute = target => {
    props.history.push(target);
  };

  const signOut = () => {
    trackEvent('sign_out');
    logout({
      returnTo: window.location.origin
    });
  };

  useEffect(() => {
    // update selected tab on route change
    setSelectedTab(getCurrentRoute(location));
  }, [location]);

  return (
    <Container maxWidth="lg">
      <Wrapper>
        <Logo src={HeaderLogo} alt="home" />
        <Tabs
          value={selectedTab}
          variant="scrollable"
          TabIndicatorProps={TabIndicator}
        >
          <Tab
            label={lookup('header_outputs')}
            {...initTabProps(0)}
            onClick={() => changeRoute(AppRoutes.OUTPUT)}
          />
          <Tab
            label={lookup('header_volumes')}
            {...initTabProps(1)}
            onClick={() => changeRoute(AppRoutes.VOLUME)}
          />
          <Tab
            label={lookup('header_impact')}
            {...initTabProps(2)}
            onClick={() => changeRoute(AppRoutes.IMPACT)}
          />
        </Tabs>
        <IconButton
          aria-label="available projects"
          aria-controls="menu-appbar-projects"
          aria-haspopup="true"
          onClick={handleProjectsMenu}
          color="inherit"
          edge="end"
        >
          {appContext.selectedProject && (
            <Typography variant="h5" color="inherit">
              <Hidden only={['xs', 'sm']}>
                {appContext.selectedProject.name}
              </Hidden>
              <Hidden mdUp>{lookup('header_projects')}</Hidden>
            </Typography>
          )}
          <ArrowDropDownIcon />
        </IconButton>
        <Menu
          id="menu-appbar-projects"
          anchorEl={anchorElProjects}
          {...MenuProps}
          open={Boolean(anchorElProjects)}
          onClose={handleProjectsMenuClose}
        >
          {appContext.projects &&
            appContext.projects.map((project, id) => {
              return (
                <MenuItem
                  selected={project.name === appContext.selectedProject.name}
                  key={id}
                  onClick={() => changeProject(project)}
                >
                  {project.name}
                </MenuItem>
              );
            })}
        </Menu>

        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar-profile"
          aria-haspopup="true"
          onClick={handleProfileMenu}
          color="inherit"
          edge="end"
        >
          {appContext?.companyLogo ? (
            <CustomerLogo src={appContext.companyLogo} />
          ) : (
            <AccountCircleIcon />
          )}
          <ArrowDropDownIcon />
        </IconButton>

        <Menu
          id="menu-appbar-profile"
          anchorEl={anchorElProfile}
          {...MenuProps}
          open={Boolean(anchorElProfile)}
          onClose={handleProfileMenuClose}
        >
          <MenuItem onClick={signOut}>{lookup('header_logout')}</MenuItem>
        </Menu>
      </Wrapper>
    </Container>
  );
};

export default withRouter(Header);
