import BugsnagClient from 'utils/bugsnag';

/**
 * Send logged in user's information to analytics
 * @param {Object} user
 */
export const setIdentity = user => {
  // i.e auth0|5e95f112c225200cf2378cd2
  const id = user?.id?.split('|')[1];
  if (id) {
    window.analytics &&
      window.analytics.identify(id, {
        name: user.displayName,
        email: user.email
      });
    try {
      BugsnagClient.setUser(id, user.displayName, user.email);
    } catch (e) {
      console.log('failed to init bugsnag client');
    }
  }
};

/**
 * Send events to analytics
 * @param {string} eventName
 * @param {string} val
 */
export const trackEvent = (eventName, val) => {
  window.analytics &&
    window.analytics.track(EVENTS[eventName], val && { label: val });
};

/**
 * Send page views to analytics
 * @param {string} pageName
 */
export const trackView = pageName => {
  window.analytics && window.analytics.page(PAGES[pageName]);
};

const EVENTS = {
  metric_filter_changed: 'Metric Filter Changed',
  alert_name_changed: 'Alert Name Filter Changed',
  weekly_toggle: 'Weekly/Cumulative Filter Changed',
  date_range_changed: 'Date Range Filter Changed',
  output_internalAlertId_changed: 'Output Alert Name Filter Changed',
  output_date_changed: 'Output Delivery Date Filter Changed',
  output_text_filter: 'User applied text filter',
  output_column_filter: 'User applied column filter',
  sign_out: 'User Signed Out',
  trigger_download: 'Download Triggered',
  ta_changed: 'Therapeutic Area Filter Changed',
  delivery_date_changed: 'Delivery Date Filter Changed',
  alert_pathway_date_range_changed: 'Alert Pathway Date Range Filter Changed',
  alert_pathway_user_clicked_add: 'Added alert by clicking Add button',
  alert_pathway_user_clicked_cancel: 'Removed alert by clicking Cancel button',
  alert_pathway_user_dragged: 'Added or removed alert by drag and drop',
  alert_pathway_user_sorted: 'Sorted alert list by drag and drop'
};

const PAGES = {
  output: 'Output',
  volume: 'Volume',
  alert_pathway: 'Alert Pathway'
};
