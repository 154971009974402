import get from 'lodash/get';
import moment from 'moment';
import { getNum } from 'utils/helpers';

class AlertMetric {
  /**
   * 'Constructor' for AlertMetric Model
   * @param {Object} obj
   */
  constructor(obj) {
    if (obj) {
      this.alertNames = initAlertNames(obj);
      this.entries = initEntries(obj, this.alertNames);
      this.companyAcronym = obj?.companyAcronym;
      this.therapyArea = obj?.therapyArea;
      //set default
      this.selectedDate = this.entries?.[0]?.date;
      this.selectedEntry = this.getSelectedEntry(this.selectedDate);
      this.latestEntry = this.getSelectedEntry(this.selectedDate);
    }
  }

  /**
   * Update selected/active week/entry
   * @param {string} date
   */
  updateSelectedEntry(date) {
    this.selectedDate = date;
    this.selectedEntry = this.getSelectedEntry(date);
  }

  /**
   * Get selected entry for given date
   * @param {string} date
   * @returns {Object}
   */
  getSelectedEntry(date) {
    return this.entries.filter(entry => entry.date === date)[0];
  }
}

class AlertSummary {
  /**
   * 'Constructor' for AlertSummary Model
   * @param {Object} obj
   */
  constructor(obj) {
    if (obj) {
      this.date = obj.delivery_date;
      this.alertBreakdown = initAlertBreakdown(obj);
      this.totalAlerts = getNum(obj.total_alert_count);
      this.totalHCPs = getNum(obj.total_hcp_count);
      this.totalPatients = getNum(obj.total_patient_count);
      this.cumulativeAlerts = getNum(obj.cumulative_alert_count);
      this.cumulativeHCPs = getNum(obj.cumulative_hcp_count);
      this.cumulativePatients = getNum(obj.cumulative_patient_count);
    }
  }
}

class Alert {
  /**
   * 'Constructor' for Alert Model
   * @param {Object} obj
   */
  constructor(obj) {
    if (obj) {
      this.id = obj.alert_id || null;
      this.internalAlertId = obj.internal_alert_id || null;
      this.name = obj.alert_name || null;
    }
  }
}

class AlertBreakdown extends Alert {
  /**
   * 'Constructor' for AlertBreakdown Model
   * @augments Alert
   * @param {Object} obj
   */
  constructor(obj) {
    if (obj) {
      super(obj);
      this.description = get(obj, 'description', '');
      this.timestamp = obj.delivery_timestamp || null;
      this.totalAlerts = getNum(obj.total_alert_count);
      this.totalHCPs = getNum(obj.total_hcp_count);
      this.totalPatients = getNum(obj.total_patient_count);
      this.cumulativeAlerts = getNum(obj.cumulative_alert_count);
      this.cumulativeHCPs = getNum(obj.cumulative_hcp_count);
      this.cumulativePatients = getNum(obj.cumulative_patient_count);
    }
  }
}

/**
 * Init entries for given Project
 * @param {Array} entries
 * @param {Array} projectDataRules
 * @param {Array} alertNames
 * @returns {Array}
 */
const initEntries = ({ entries, projectDataRules }, alertNames) => {
  if (Array.isArray(entries) && Array.isArray(projectDataRules)) {
    // filter entries w/o date and exclude undelivered
    return entries
      .filter(
        entry =>
          entry.delivery_date &&
          moment(entry.delivery_date).isSameOrBefore(moment(), 'day')
      )
      .map(entry => {
        return new AlertSummary({ ...entry, alertNames, projectDataRules });
      });
  }
  return [];
};

/**
 * Init Alerts for given alert_names array
 * @param {Array} alert_names available alert names for given project
 * @param {Array} projectDataRules project data rules for selected project
 * @returns {Array} array of active Alerts sorted by id
 */
const initAlertNames = ({ alert_names, projectDataRules }) => {
  if (Array.isArray(alert_names) && Array.isArray(projectDataRules)) {
    return alert_names
      .filter(alert => alert.alert_name)
      .map(alert => {
        const { alert_id } =
          projectDataRules.filter(
            pdr => alert.internal_alert_id === pdr.id
          )?.[0] || {};
        return new Alert({ ...alert, alert_id });
      })
      .sort((a, b) => {
        return (
          (a.id === null) - (b.id === null) || +(a.id > b.id) || -(a.id < b.id)
        );
      });
  }
  return [];
};

/**
 * Init AlertBreakdown for given alert_names array
 * @param {Array} breakdown weekly alert names breakdown
 * @param {Array} alertNames available alert names for selected project
 * @param {Array} projectDataRules project data rules for selected project
 * @returns {Array}
 */
const initAlertBreakdown = ({ breakdown, alertNames, projectDataRules }) => {
  if (Array.isArray(breakdown) && Array.isArray(alertNames)) {
    return alertNames.map(alert => {
      const targetAlert = breakdown.filter(
        ({ internal_alert_id }) => internal_alert_id === alert.internalAlertId
      )[0];
      if (targetAlert) {
        const targetProjectDataRule = projectDataRules.filter(
          pdr => pdr.id === targetAlert.internal_alert_id
        )[0];

        const { delivery_timestamp, description, alert_id, alert_name } =
          targetProjectDataRule || {};

        return new AlertBreakdown({
          ...targetAlert,
          delivery_timestamp,
          description,
          alert_id,
          // use alert_name from pdr
          alert_name
        });
      } else {
        const targetProjectDataRule = projectDataRules.filter(
          pdr => pdr.id === alert.internalAlertId
        )[0];

        const { delivery_timestamp, description } = targetProjectDataRule || {};
        // include delivery_timestamp and description even if no match found in breakdown
        return {
          ...new AlertBreakdown({ delivery_timestamp, description }),
          ...alert
        };
      }
    });
  }
  return [];
};

export { AlertMetric };
