import ENDPOINT from 'utils/api';
import { handleErrors } from 'utils/error';
import { getTextWidth } from 'utils/helpers';

class AlertOutputService {
  /**
   * 'Constructor' for AlertOutputService
   * @param {string} token auth0 token
   */
  constructor(token) {
    this.path = `${ENDPOINT}alert_output`;
    this.requestHeader = {
      headers: {
        'Access-Token': token,
        'Content-Type': 'application/json'
      }
    };
  }

  /**
   * Get /alert_output
   * @async
   * @param {string} internal_alert_id
   * @param {string} delivery_date
   * @param {Object} font header font
   * @return {Promise} output file.
   */
  async get(internal_alert_id, delivery_date, font) {
    try {
      const res = await fetch(
        `${this.path}/${internal_alert_id}/${delivery_date}`,
        this.requestHeader
      );
      if (!res.ok) {
        throw res;
      }
      const data = await res.json();
      data.columns.forEach(column => {
        // calculate width for each column, + 80px for icons
        column.minWidth = getTextWidth(column.field, font, 80);
      });
      return data;
    } catch (e) {
      throw await handleErrors(e);
    }
  }
}

export { AlertOutputService };
