import { useState } from 'react';
import PatientLogo from './patient-logo.svg';
import AlertLogo from './alert-logo.svg';
import HCPLogo from './hcp-logo.svg';
import SummaryCard from './SummaryCard';
import {
  Grid,
  Container as MuiContainer,
  InputLabel,
  FormControl as MuiFormControl,
  MenuItem,
  Typography
} from '@material-ui/core';
import Title from 'components/common/title/';
import Info from 'components/common/info';
import Card from '../card';
import moment from 'moment';
import DATE_FORMATS from 'constants/DateFormats';
import Select from 'styled/Select';
import { trackEvent } from 'utils/analytics';
import styled from 'styled-components';
import I18n from 'i18n';
const { lookup } = new I18n();

const Container = styled(MuiContainer)(
  p => `
    padding-bottom: ${p.theme.space.sm};
    padding-top: ${p.theme.space.sm};
  `
);

const FormControl = styled(MuiFormControl)(
  p => `
    flex-direction: row;
    align-items: center;
    .MuiInputLabel-formControl{
      position: relative;
    }
    .MuiInput-formControl{
      margin: 0;
    }

  `
);

const TitleWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const Summary = ({ selectedProject }) => {
  const [state, setState] = useState({
    selectedProject
  });

  const handleDateChange = e => {
    const { value } = e.target;
    setState({
      ...state,
      ...state.selectedProject.updateSelectedEntry(value)
    });
    trackEvent('delivery_date_changed', value);
  };

  return (
    <>
      <Title id="alert-summary" font="h4" nopt>
        <TitleWrapper>
          {lookup('section_alert_summary')}
          <Typography variant="body2">
            &nbsp;{lookup('section_alert_summary_all_alerts')}
          </Typography>
        </TitleWrapper>
        <Info copy={lookup('tooltip_alert_summary')} />
      </Title>
      <Card>
        <Container>
          <FormControl>
            <InputLabel>{lookup('filter_delivery_date_label')}</InputLabel>
            <Select
              id="summary-select"
              aria-label="alerts delivered on"
              value={state.selectedProject.selectedDate}
              onChange={handleDateChange}
              width="auto"
            >
              {state.selectedProject.entries.map((entry, id) => {
                return (
                  <MenuItem key={id} value={entry.date}>
                    {moment(entry.date).format(DATE_FORMATS.standard)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <SummaryCard
                img={AlertLogo}
                label={lookup('card_alerts')}
                value={state.selectedProject.selectedEntry.totalAlerts}
                border={1}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <SummaryCard
                img={HCPLogo}
                label={lookup('card_hcps')}
                value={state.selectedProject.selectedEntry.totalHCPs}
                border={1}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <SummaryCard
                img={PatientLogo}
                label={lookup('card_patients')}
                value={state.selectedProject.selectedEntry.totalPatients}
              />
            </Grid>
          </Grid>
        </Container>
      </Card>
    </>
  );
};

export default Summary;
