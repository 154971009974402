const HTTP_401_UNAUTHORIZED = 401;

class APIError extends Error {
  /**
   * 'Constructor' for APIError
   * @augments Error
   * @param {string} message
   * @param {integer} status
   */
  constructor(message, { status }) {
    super();
    this.message = message;
    this.AUTH_ERROR = status === HTTP_401_UNAUTHORIZED;
  }
}

/**
 * Handle API errors
 * Used in services to detect error type and throw
 * @async
 * @param {Object} err error object
 * @throws {Error}
 */
export async function handleErrors(err) {
  if (err.json) {
    const { message = null } = await err.json();
    if (message) {
      throw new APIError(message, err);
    } else {
      throw err;
    }
  }
  throw err;
}

/**
 * Handle error messages
 * Used in views to handle errors based on error type
 * i.e redirect to login or trigger error boundaries
 * @param {Object} err error object
 * @param {function} authErrorHandler error handler for authorization errors
 * @param {function} genericErrorHandler error handler for generic errors
 */
export const handleErrorMessage = (
  err,
  authErrorHandler,
  genericErrorHandler
) => {
  if (err?.AUTH_ERROR) {
    authErrorHandler({ returnTo: window.location.origin });
  } else {
    genericErrorHandler(() => {
      throw new Error(err);
    });
  }
};
