import MuiAppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';
import Header from '../header';
import Footer from '../footer';
import Link from 'components/common/link';
import I18n from 'i18n';
const { lookup } = new I18n();

const Content = styled.div`
  // min-height for footer
  min-height: calc(100vh - 50px);
  padding: ${p => p.theme.fixed.headerHeight}px 0 ${p => p.theme.space.lg} 0;
`;

const AppBar = styled(MuiAppBar)(
  p => `
  background-image: ${p.theme.colors.gradientBackground};
  box-shadow: none;
`
);

const Layout = props => {
  return (
    <>
      <AppBar>
        <Header />
      </AppBar>
      <Content>{props.children}</Content>
      <Footer>
        {`${lookup('copyright')} ${lookup(
          'copyright_start_year_text'
        )} ${new Date().getFullYear()} ${lookup('footer_text')}`}
        <Link href={`${lookup('terms_of_use_link')}`}>{`${lookup(
          'terms_of_use_text'
        )}`}</Link>
        <Link href={`${lookup('privacy_notice_link')}`}>{`${lookup(
          'privacy_notice_text'
        )}`}</Link>
      </Footer>
    </>
  );
};

export default withTheme(Layout);
