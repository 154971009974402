import { useState, useEffect } from 'react';
import ChartContainer from '../chart';
import ChartFilter from '../filters/ChartFilter';
import Container from '@material-ui/core/Container';
import Divider from 'components/common/divider';
import Card from '../card';
import { LineChart } from '../chart/config/LineChart';
import { BarChart } from '../chart/config/BarChart';
import {
  MetricChartConfig,
  MetricChartFilterConfig
} from 'constants/AppConstants';
import DATE_FORMATS from 'constants/DateFormats';
import moment from 'moment';
import chunk from 'lodash/chunk';
import { withTheme } from '@material-ui/core/styles';
import { trackEvent } from 'utils/analytics';
import I18n from 'i18n';
const { lookup } = new I18n();

/**
 * Splits Array of entries into chunks and returns first item
 * @param {Object} selectedProject
 * @param {integer} chunkSize
 * @returns {Object}
 */
const getInitalChunk = ({ selectedProject }, chunkSize) => {
  return chunk(selectedProject.entries, chunkSize)[0];
};

/**
 * Returns index of start and end date for given chunk
 * @param {Array} dates
 * @param {Array} chunk
 * @returns {Object}
 */
const getStartAndEndDates = (dates, chunk) => {
  return {
    startDate: dates.indexOf(chunk[chunk.length - 1].date),
    endDate: dates.indexOf(chunk[0].date)
  };
};

/**
 * Filters weekly entries based on selected metric, alert name and timeframe
 * @param {string} metric selected metric type
 * @param {boolean} isWeekly weekly/cumulative flag
 * @param {string} internalAlertId selected internalAlertId
 * @param {string} alertNames list of alerts for given project
 * @param {Object} config Chart configuration
 * @param {Array} chunk selected chunk of entries
 * @returns {Object}
 */
const filterAndPrepareEntries = (
  { metric, isWeekly, internalAlertId, alertNames, props: { theme } },
  config,
  chunk
) => {
  // deep clone selected chunk and reverse
  const entries = chunk.slice().reverse();

  const categories = entries.map(entry => {
    return moment(entry.date).format(DATE_FORMATS.standard);
  });

  let data;
  let chartLegend = {};

  if (internalAlertId === MetricChartFilterConfig.showAll.internalAlertId) {
    data = entries.map(entry => {
      if (isWeekly) {
        return entry[config[metric].weekly.key];
      }
      return entry[config[metric].cumulative.key];
    });
  } else {
    data = entries.map(entry => {
      // find selected alert from breakdown
      const selectedAlert = entry.alertBreakdown.filter(
        alert => alert.internalAlertId === internalAlertId
      )[0];

      if (isWeekly) {
        return selectedAlert[config[metric].weekly.key];
      }
      return selectedAlert[config[metric].cumulative.key];
    });
    chartLegend = {
      name: alertNames.filter(
        alert => alert.internalAlertId === internalAlertId
      )[0].name
    };
  }

  const series = [
    {
      ...config[metric][isWeekly ? 'weekly' : 'cumulative'],
      data,
      ...chartLegend
    }
  ];

  const options = {
    xAxisTitle: config.xAxisTitle,
    yAxisTitle: `${lookup('filter_alert_type_placeholder')} ${lookup(
      `filter_metric_type_number_of_${metric}`
    )}`
  };

  return {
    categories,
    series,
    options,
    theme
  };
};

const Metric = props => {
  const [state, setState] = useState({});
  const [chartData, setChartData] = useState(null);

  const handleMetricChange = e => {
    setState({
      ...state,
      metric: e.target.value
    });
    trackEvent('metric_filter_changed', e.target.value);
  };

  const handleAlertNameChange = e => {
    setState({
      ...state,
      internalAlertId: e.target.value
    });
    trackEvent('alert_name_changed', e.target.value);
  };

  const handleWeeklyToggle = isWeekly => {
    setState({
      ...state,
      isWeekly: isWeekly
    });
    trackEvent('weekly_toggle', isWeekly ? 'weekly' : 'cumulative');
  };

  const updateDateRange = ({ startDate, endDate }) => {
    const chunk = props.selectedProject.entries.filter(
      entry =>
        moment(entry.date).isSameOrBefore(
          moment(endDate, DATE_FORMATS.standard),
          'day'
        ) &&
        moment(entry.date).isSameOrAfter(
          moment(startDate, DATE_FORMATS.standard),
          'day'
        )
    );

    setState({
      ...state,
      chunk,
      ...getStartAndEndDates(state.dates, chunk)
    });
    trackEvent('date_range_changed');
  };

  useEffect(() => {
    const initChart = state => {
      const chartData = filterAndPrepareEntries(
        { ...state },
        MetricChartConfig,
        state.chunk
      );

      if (state.isWeekly) {
        return LineChart(chartData);
      } else {
        return BarChart(chartData);
      }
    };

    if (props === state.props) {
      // set chart data
      setChartData(initChart(state));
    } else {
      // add show_all to alert names
      const alertNames = [
        ...props.selectedProject.alertNames,
        MetricChartFilterConfig.showAll
      ];
      // set initial state
      // reset to default if appContext changes
      // i.e Global filters such as TA

      const chunk = getInitalChunk(props, MetricChartFilterConfig.chunkSize);
      const dates = props.selectedProject.entries
        .map(item => item.date)
        .reverse();

      setState({
        metric: MetricChartFilterConfig.defaultMetric,
        internalAlertId: alertNames[0].internalAlertId,
        alertNames,
        isWeekly: true,
        chunk,
        dates,
        ...getStartAndEndDates(dates, chunk),
        props
      });
    }
  }, [props, state]);

  return (
    <Card>
      {state.chunk && (
        <Container>
          <ChartFilter
            {...state}
            handleMetricChange={handleMetricChange}
            handleAlertNameChange={handleAlertNameChange}
            handleWeeklyToggle={handleWeeklyToggle}
            updateDateRange={updateDateRange}
          />
          <Divider md />
          <ChartContainer chart={chartData} />
        </Container>
      )}
    </Card>
  );
};

export default withTheme(Metric);
