class Project {
  /**
   * 'Constructor' for Project Model
   * @param {Object} obj
   */
  constructor(obj) {
    if (obj) {
      this.id = obj.id;
      this.name = obj.name;
      this.label = obj.description;
      this.therapyArea = obj.therapy_area;
    }
  }

  /**
   * Converts data into an array of instances of 'this'
   * @param {Object} cloudResult
   * @returns {Array}
   * @throws If the cloudResult cannot be handled properly, throw an error
   */
  static init(cloudResult) {
    if (!Array.isArray(cloudResult)) {
      throw new Error('Cloud response was not array');
    }
    // filter Projects w/o name, description or therapy_area
    return cloudResult
      .filter(item => item.name && item.description && item.therapy_area)
      .map(item => {
        return new this(item);
      });
  }

  /**
   * Get default Project
   * @param {Array} projects
   * @returns {Object}
   */
  static getDefaultProject(projects) {
    return projects?.[0];
  }
}

export { Project };
