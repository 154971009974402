import MuiLink from '@material-ui/core/Link';
import styled from 'styled-components';

const Link = styled(MuiLink)(
  ({ theme }) => `
  color: ${theme.colors.light};
  text-decoration: underline;
	transition: 0.2s;
	&:hover {
	  color: ${theme.colors.light};
	}
`
);

export default Link;
