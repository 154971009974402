import { ENLocalizedStrings } from './en.js';

// Temp and basic localization solution
class I18n {
  constructor(locale = 'en') {
    this.locale = locale;
    this.lookup = this.lookup.bind(this);
  }

  localizedStrings() {
    return ENLocalizedStrings;
  }

  lookup(keyName) {
    return this.localizedStrings()[keyName] || '';
  }
}

export default I18n;
