import styled, { css } from 'styled-components';

export default styled.div(
  p => css`
    // fix for IE
    display: flex;
    align-items: center;
    padding: ${p.theme.space.md} 0;
    ${({ font }) =>
      font
        ? css`
            ${p.theme.typography[font]};
          `
        : css`
            ${p.theme.typography.h3};
          `}
    ${({ nopt }) =>
      nopt &&
      `
        padding-top: 0;
      `}
    // force consistent line-height
    line-height: 40px;
  `
);
