import * as React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const BugsnagClient = Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_ID,
  plugins: [new BugsnagPluginReact(React)],
  enabledReleaseStages: ['production']
});

export default BugsnagClient;
