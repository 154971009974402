import { createTheme as createThemeV4 } from '@material-ui/core/styles';
import { createTheme as createThemeV5 } from '@mui/material/styles';

//colors
export const colors = {
  white: '#ffffff',
  komodoPurple: '#970e76',
  brand: '#151B2B',
  light: '#efefef',
  secondaryBackgroundGrey: '#e0e0e0',
  borderGrey: '#cccccc',
  highlightTeal: '#009999',
  textGrey: '#404040',
  gradientBackground: 'linear-gradient(to bottom, #151B2B, #151B2B 77%)',
  stripe: 'rgba(239, 239, 239, 0.5)',
  errorText: 'rgb(97, 26, 21)',
  errorBackground: 'rgb(253, 236, 234)',
  loaderBackdrop: 'rgba(0, 0, 0, 0.1)'
};

//spacing
const space = {
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '32px'
};

//constant/fixed values
const fixed = {
  headerHeight: '70'
};

//effects
const effects = {
  boxShadow: '0 0 10px 2px rgba(0, 0, 0, 0.1)',
  boxShadowBottom: '0 10px 10px -10px rgba(0, 0, 0, 0.1)',
  tableBorder: `solid 1px ${colors.borderGrey}`
};

const typography = {
  body: {
    fontSize: '14px',
    color: colors.textGrey,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  button: {
    fontSize: '14px',
    color: colors.textGrey,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none'
  },
  h1: {
    fontSize: '28px',
    color: colors.textGrey,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  h2: {
    fontSize: '26px',
    color: colors.textGrey,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  h3: {
    fontSize: '22px',
    color: colors.textGrey,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  h4: {
    fontSize: '18px',
    color: colors.textGrey,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  h4Inverse: {
    fontSize: '18px',
    color: colors.light,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  h5: {
    fontSize: '16px',
    color: colors.textGrey,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  h6: {
    fontSize: '14px',
    color: colors.textGrey,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  },
  tooltip: {
    fontSize: '14px',
    color: colors.white,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'
  }
};

const muiTheme = {
  colors: {
    ...colors
  },
  space: {
    ...space
  },
  effects: {
    ...effects
  },
  fixed: {
    ...fixed
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920
    }
  },
  palette: {
    primary: {
      main: colors.komodoPurple
    }
  },
  typography: {
    fontFamily: ['Open Sans'].join(','),
    ...typography
  },
  overrides: {
    MuiTab: {
      root: {
        textTransform: 'none'
      }
    }
  }
};

const themeV4 = createThemeV4(muiTheme);
const themeV5 = createThemeV5(muiTheme);

export { themeV4, themeV5 };
