import ENDPOINT from 'utils/api';
import { handleErrors } from 'utils/error';

/**
 * Authenticate user
 * @async
 * @param {string} email user's email
 * @param {string} token auth0 token
 * @return {Promise} user data.
 */
async function initUser({ email }, token) {
  try {
    const res = await fetch(`${ENDPOINT}authorization/${email}`, {
      headers: {
        'Access-Token': token
      }
    });
    if (!res.ok) {
      throw res;
    }
    return res;
  } catch (e) {
    throw await handleErrors(e);
  }
}

export { initUser };
