import I18n from 'i18n';
import { colors } from 'constants/theme';
const { lookup } = new I18n();

export const MetricTypes = {
  ALERT: 'alert',
  HCP: 'hcp',
  PATIENT: 'patient'
};

export const MetricChartFilterConfig = {
  defaultMetric: MetricTypes.ALERT,
  metricTypes: [
    {
      type: MetricTypes.ALERT,
      label: lookup('filter_metric_type_number_of_alert')
    },
    {
      type: MetricTypes.HCP,
      label: lookup('filter_metric_type_number_of_hcp')
    },
    {
      type: MetricTypes.PATIENT,
      label: lookup('filter_metric_type_number_of_patient')
    }
  ],
  showAll: {
    id: 'show_all',
    internalAlertId: 'show_all',
    name: lookup('filter_show_all_label')
  },
  chunkSize: 6
};

export const MetricChartConfig = {
  xAxisTitle: lookup('metric_x_axis_title'),
  [MetricTypes.ALERT]: {
    weekly: {
      key: 'totalAlerts',
      name: lookup('chart_alert_legend'),
      type: 'line',
      legendIndex: 0,
      color: colors.komodoPurple
    },
    cumulative: {
      key: 'cumulativeAlerts',
      name: lookup('chart_alert_legend'),
      type: 'column',
      legendIndex: 0,
      color: colors.komodoPurple
    }
  },
  [MetricTypes.HCP]: {
    weekly: {
      key: 'totalHCPs',
      name: lookup('chart_hcp_legend'),
      type: 'line',
      legendIndex: 0,
      color: colors.komodoPurple
    },
    cumulative: {
      key: 'cumulativeHCPs',
      name: lookup('chart_hcp_legend'),
      type: 'column',
      legendIndex: 0,
      color: colors.komodoPurple
    }
  },
  [MetricTypes.PATIENT]: {
    weekly: {
      key: 'totalPatients',
      name: lookup('chart_patient_legend'),
      type: 'line',
      legendIndex: 0,
      color: colors.komodoPurple
    },
    cumulative: {
      key: 'cumulativePatients',
      name: lookup('chart_patient_legend'),
      type: 'column',
      legendIndex: 0,
      color: colors.komodoPurple
    }
  }
};

export const DOWNLOAD_THRESHOLD_IN_DAYS = 90;
