import { useState, useEffect, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from 'styled/IconButton';
import Slider from 'components/common/slider';
import moment from 'moment';
import DATE_FORMATS from 'constants/DateFormats';
import { trackEvent } from 'utils/analytics';
import Info from 'components/common/info';
import I18n from 'i18n';
import Typography from '@material-ui/core/Typography';
import MuiAvatar from '@material-ui/core/Avatar';

const { lookup } = new I18n();

const KEYS = {
  SELECTED_ITEMS: 'selectedItems',
  AVAILABLE_ITEMS: 'availableItems'
};

// hardcoded to 90 days here, can move this to context if we end up having custom configuration
const INITITAL_DATE_RANGE = 90;

const DragContainer = styled.div(
  p => `
  display: flex;
  flex-direction: column;
  padding: 0 ${p.theme.space.md} 0 0;
`
);

const List = styled.div(
  p => css`
    width: auto;
    height: 300px;
    overflow: auto;
    background: ${p.isDraggingOver
      ? p.theme.colors.light
      : p.theme.colors.stripe};

    ${p.isEmpty &&
    css`
      overflow: hidden;
      border: dashed 1px ${p.theme.colors.komodoPurple};
      &:before {
        ${p.theme.typography.h5};
        content: '${lookup('roi_drag_placeholder')}';
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    `}
  `
);

const ListItem = styled.div(
  p => `
  word-break: break-all;
  background-color: ${p.theme.colors.white};
  display: flex;
  align-items: center;
  padding: ${p.theme.space.sm} 0;
  // temp borders, will change 
  border-radius: 4px;
  border: 2px solid ${p.theme.colors.light};
`
);

const ListItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ListNumber = styled(MuiAvatar)(
  p => css`
    width: 24px;
    height: 24px;
    margin-right: ${p.theme.space.sm};
    ${p.theme.typography.h6};
  `
);

const ListFooter = styled.div(
  p => `
  display: flex;
  justify-content: flex-end;
  padding: ${p.theme.space.lg} ${p.theme.space.md} 0 0;
  align-items: center;
`
);

const SliderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const SliderContainer = styled.div`
  ${({ theme }) => `
    max-width: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: ${theme.space.md};
  `}
`;

const FilterLabel = styled(Typography)(
  p => `
  padding-bottom: ${p.theme.space.md};
`
);

// reorder list
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Move item from one list to other
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

// get initial start and end dates for date range picker
const getStartAndEndDates = entries => {
  const dates = entries.map(entry => entry.date);
  const reversedDates = dates.slice().reverse();

  const endDate = dates[0];
  let startDate = dates.find(
    date => moment(endDate).diff(moment(date), 'days') >= INITITAL_DATE_RANGE
  );

  // if less than 90 days available, assign first delivery as startDate
  if (!startDate) {
    startDate = dates[dates.length - 1];
  }

  return {
    allDates: reversedDates,
    startDate: reversedDates.indexOf(startDate),
    endDate: reversedDates.indexOf(endDate)
  };
};

const DragList = ({ selectedProject, applyFilters }) => {
  const [state, setState] = useState({
    [KEYS.AVAILABLE_ITEMS]: [],
    [KEYS.SELECTED_ITEMS]: []
  });

  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
    allDates: []
  });

  const [chartLoading, setChartLoading] = useState(false);

  useEffect(() => {
    setState({
      [KEYS.AVAILABLE_ITEMS]: selectedProject.alertNames.map(item => {
        return {
          content: item.name,
          internalAlertId: item.internalAlertId
        };
      }),
      [KEYS.SELECTED_ITEMS]: []
    });

    setDates({
      ...getStartAndEndDates(selectedProject.entries)
    });
  }, [selectedProject]);

  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    // Sorting in same list
    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        state[source.droppableId],
        source.index,
        destination.index
      );
      setState({
        ...state,
        [source.droppableId]: items
      });
      trackEvent('alert_pathway_user_sorted');
    }
    // Interlist movement
    else {
      const result = move(
        state[source.droppableId],
        state[destination.droppableId],
        source,
        destination
      );

      setState({
        [KEYS.AVAILABLE_ITEMS]: result.availableItems,
        [KEYS.SELECTED_ITEMS]: result.selectedItems
      });
      trackEvent('alert_pathway_user_dragged');
    }
  };

  const handleCancel = index => {
    const source = { droppableId: [KEYS.SELECTED_ITEMS], index };
    const destination = {
      droppableId: [KEYS.AVAILABLE_ITEMS],
      index: state.availableItems.length
    };

    const result = move(
      state[source.droppableId],
      state[destination.droppableId],
      source,
      destination
    );

    setState({
      [KEYS.AVAILABLE_ITEMS]: result.availableItems,
      [KEYS.SELECTED_ITEMS]: result.selectedItems
    });
    trackEvent('alert_pathway_user_clicked_cancel');
  };

  const handleAdd = index => {
    const source = { droppableId: [KEYS.AVAILABLE_ITEMS], index };
    const destination = {
      droppableId: [KEYS.SELECTED_ITEMS],
      index: state.selectedItems.length
    };

    const result = move(
      state[source.droppableId],
      state[destination.droppableId],
      source,
      destination
    );

    setState({
      [KEYS.AVAILABLE_ITEMS]: result.availableItems,
      [KEYS.SELECTED_ITEMS]: result.selectedItems
    });
    trackEvent('alert_pathway_user_clicked_add');
  };

  const handleApply = () => {
    const params = {
      selected: state.selectedItems.map(item => item.internalAlertId),
      startDate: dates.allDates[dates.startDate],
      endDate: dates.allDates[dates.endDate]
    };
    setChartLoading(true);
    applyFilters(params).then(() => setChartLoading(false));
  };

  return (
    <>
      <FilterLabel variant="h6" color="inherit">
        {lookup('roi_filter_info')}
      </FilterLabel>
      <DragContainer>
        <SliderWrapper>
          <SliderContainer>
            {useMemo(() => {
              if (dates.allDates?.length > 1) {
                return (
                  <>
                    <Slider
                      key={Math.random()}
                      tooltips={true}
                      format={{
                        from: Number,
                        to: value => {
                          return moment(dates.allDates[parseInt(value)]).format(
                            DATE_FORMATS.standard
                          );
                        }
                      }}
                      onSet={val => {
                        if (
                          val[0] !==
                            moment(dates.allDates[dates.startDate]).format(
                              DATE_FORMATS.standard
                            ) ||
                          val[1] !==
                            moment(dates.allDates[dates.endDate]).format(
                              DATE_FORMATS.standard
                            )
                        ) {
                          setDates({
                            ...dates,
                            startDate: dates.allDates.indexOf(
                              moment(val[0], DATE_FORMATS.standard).format(
                                DATE_FORMATS.iso
                              )
                            ),
                            endDate: dates.allDates.indexOf(
                              moment(val[1], DATE_FORMATS.standard).format(
                                DATE_FORMATS.iso
                              )
                            )
                          });
                          trackEvent('alert_pathway_date_range_changed');
                        }
                      }}
                      behaviour="tap-drag"
                      range={{ min: 0, max: dates.allDates.length - 1 }}
                      start={[dates.startDate, dates.endDate]}
                      connect
                    />
                    <Info
                      placement="bottom-end"
                      copy={lookup('tooltip_roi_range_picker')}
                    />
                  </>
                );
              } else if (dates.allDates?.length === 1) {
                // don't render slider for less than 2 deliveries
                return (
                  <FilterLabel variant="h5" color="inherit">
                    {moment(dates.allDates[0]).format(DATE_FORMATS.standard)}
                  </FilterLabel>
                );
              }
            }, [dates])}
          </SliderContainer>
        </SliderWrapper>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FilterLabel variant="h5" color="inherit">
              {`${lookup('roi_filter_available_alerts')} (${
                state[KEYS.AVAILABLE_ITEMS].length
              })`}
            </FilterLabel>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FilterLabel variant="h5" color="inherit">
              {`${lookup('roi_filter_selected_alerts')} (${
                state[KEYS.SELECTED_ITEMS].length
              })`}
            </FilterLabel>
          </Grid>
        </Grid>
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Droppable droppableId={KEYS.AVAILABLE_ITEMS}>
                {(provided, snapshot) => (
                  <List
                    isDraggingOver={snapshot.isDraggingOver}
                    ref={provided.innerRef}
                  >
                    {state.availableItems.map((item, index) => (
                      <Draggable
                        key={`${item.content}-${item.internalAlertId}`}
                        draggableId={`${item.content}-${item.internalAlertId}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DragIndicatorIcon color="action" />
                            <ListItemContent>
                              {item.content}
                              <IconButton
                                data-testid="select-alert"
                                onClick={() => handleAdd(index)}
                                size="small"
                              >
                                <AddIcon color="action" />
                              </IconButton>
                            </ListItemContent>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Droppable droppableId={KEYS.SELECTED_ITEMS}>
                {(provided, snapshot) => (
                  <List
                    isEmpty={state[KEYS.SELECTED_ITEMS].length === 0}
                    isDraggingOver={snapshot.isDraggingOver}
                    ref={provided.innerRef}
                  >
                    {state.selectedItems.map((item, index) => (
                      <Draggable
                        key={`${item.content}-${item.internalAlertId}`}
                        draggableId={`${item.content}-${item.internalAlertId}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DragIndicatorIcon color="action" />
                            <ListNumber>{index + 1}</ListNumber>
                            <ListItemContent>
                              {item.content}
                              <IconButton
                                onClick={() => handleCancel(index)}
                                size="small"
                              >
                                <CloseIcon color="action" />
                              </IconButton>
                            </ListItemContent>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </DragDropContext>
      </DragContainer>
      <ListFooter>
        <Button
          disabled={state.selectedItems.length < 2 || chartLoading}
          variant="contained"
          color="primary"
          size="large"
          onClick={handleApply}
        >
          {lookup('roi_filter_apply_label')}
        </Button>
      </ListFooter>
    </>
  );
};

export default DragList;
