import ENDPOINT from 'utils/api';
import { AlertMetric } from 'models';
import { handleErrors } from 'utils/error';

/**
 * Get dashboard data for selected project
 * @async
 * @param {string} id project id
 * @param {string} companyId company id
 * @param {string} token auth0 token
 * @return {AlertMetric} dashboard data.
 */
async function initDashboard({
  selectedProject: { id, therapyArea },
  companyId,
  companyAcronym,
  token
}) {
  const alertMetrics = await fetchAlertMetrics(companyId, id, token);
  const projectDataRules = await fetchProjectDataRules(id, token);
  return new AlertMetric({
    ...alertMetrics,
    projectDataRules,
    therapyArea,
    companyAcronym
  });
}

/**
 * Fetch /alert_metrics
 * @async
 * @param {string} companyId company id
 * @param {string} projectId project id
 * @param {string} token auth0 token
 * @return {Promise} alert metrics.
 */
async function fetchAlertMetrics(companyId, projectId, token) {
  try {
    const res = await fetch(
      `${ENDPOINT}alert_metrics/${companyId}/${projectId}`,
      {
        headers: {
          'Access-Token': token
        }
      }
    );
    if (!res.ok) {
      throw res;
    }
    return res.json();
  } catch (e) {
    throw await handleErrors(e);
  }
}

/**
 * Fetch /project_data_rules
 * @async
 * @param {string} projectId project id
 * @param {string} token auth0 token
 * @return {Promise} project data rules.
 */
async function fetchProjectDataRules(projectId, token) {
  try {
    const res = await fetch(
      `${ENDPOINT}project_data_rules?project_id=${projectId}`,
      {
        headers: {
          'Access-Token': token
        }
      }
    );
    if (!res.ok) {
      throw res;
    }
    return res.json();
  } catch (e) {
    throw await handleErrors(e);
  }
}

export { initDashboard };
