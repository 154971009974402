const LineChart = props => {
  const { categories, series, theme, options } = props;

  return {
    credits: {
      enabled: false
    },
    chart: {
      style: {
        fontFamily: theme.typography.fontFamily
      }
    },
    title: {
      text: ''
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        stickyTracking: false,
        lineWidth: 3,
        marker: {
          symbol: 'circle',
          radius: 6
        }
      }
    },
    tooltip: {
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      formatter: function () {
        return `<table style='background-color:${
          this.series.color
        };padding: 5px; border-radius: 2px'><tr><td style="text-align: center;"><b>${this.y.toLocaleString()}</b></td></tr><tr><td style="text-align: center;">${
          this.series.name
        }</td></tr></table>`;
      },
      style: theme.typography.tooltip
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: options.xAxisTitle,
        style: theme.typography.h6,
        margin: 16
      },
      categories: categories,
      tickLength: 0,
      labels: {
        enabled: true,
        style: theme.typography.h6,
        rotation: -45
      }
    },
    yAxis: {
      minTickInterval: 1,
      labels: {
        format: '{value:,.0f}',
        style: theme.typography.h6
      },
      title: {
        text: options.yAxisTitle,
        style: theme.typography.h6,
        x: -16,
        margin: 16
      }
    },
    series: series
  };
};

export { LineChart };
