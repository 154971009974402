import styled, { css } from 'styled-components';
import Container from '@material-ui/core/Container';

const Wrapper = styled.footer(
  p => css`
    display: flex;
    align-items: center;
    height: 50px;
    background-color: ${p.theme.colors.brand};
    ${p.theme.typography.h5};
    color: ${p.theme.colors.light};
  `
);

const FooterContainer = styled(Container)(
  p => css`
    display: inline-flex;
    flex-wrap: wrap;
    gap: 25px;
  `
);

const Footer = props => {
  return (
    <Wrapper aria-label="footer">
      <FooterContainer>{props.children}</FooterContainer>
    </Wrapper>
  );
};

export default Footer;
