import MuiBackdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

const Backdrop = styled(MuiBackdrop)(
  p => `
  background-color: ${p.theme.colors.white};
`
);

const Loader = ({ loading }) => {
  return (
    <>
      {loading ? (
        <Backdrop open={loading}>
          <CircularProgress />
        </Backdrop>
      ) : null}
    </>
  );
};

export default Loader;
