import ENDPOINT from 'utils/api';
import { AlertConversions } from 'models';
import { handleErrors } from 'utils/error';

/**
 * Get conversion for selected project and alerts
 * @async
 * @param {Object} context appContext
 * @param {Object} params params for conversion
 * @return {Object} conversions.
 */
async function initConversion(context, params) {
  const conversion = await fetchConversion(context, params);
  return new AlertConversions(conversion);
}

/**
 * Fetch /alert_conversions
 * @async
 * @param {string} id project id
 * @param {string} token auth0 token
 * @param {Array} selected selected alerts' internalAlertId(s)
 * @param {string} startDate alert start date
 * @param {string} endDate alert end date
 * @return {Promise} conversions.
 */
async function fetchConversion(
  { selectedProject: { id }, token },
  { selected, startDate, endDate }
) {
  const selectedAlerts = new URLSearchParams();
  selected.forEach(param => {
    selectedAlerts.append('selected_alert', param);
  });

  try {
    const res = await fetch(
      `${ENDPOINT}alert_conversions?project_id=${id}&${selectedAlerts.toString()}&start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          'Access-Token': token
        }
      }
    );
    if (!res.ok) {
      throw res;
    }
    return res.json();
  } catch (e) {
    throw await handleErrors(e);
  }
}

export { initConversion };
